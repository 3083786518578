"use client";
import React, { useEffect } from "react";
import Intercom from "@intercom/messenger-js-sdk";

interface IntercomClientComponentProps {
  userId?: string;
  userName?: string;
  userEmail?: string;
  userCreatedAt?: number; // Unix timestamp in seconds
}

const IntercomClientComponent: React.FC<IntercomClientComponentProps> = ({
  userId,
  userName,
  userEmail,
  userCreatedAt,
}) => {
  useEffect(() => {
    const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

    if (!INTERCOM_APP_ID) {
      console.error("Intercom App ID is missing");
      return;
    }

    Intercom({
      app_id: INTERCOM_APP_ID,
      user_id: userId,
      name: userName,
      email: userEmail,
      created_at: userCreatedAt,
    });

    // Update Intercom on route changes or view updates
    window?.Intercom?.("update");
  }, [userId, userName, userEmail, userCreatedAt]);

  return null; // This component does not render anything visually.
};

export default IntercomClientComponent;
