import * as React from "react";
import styles from "./Dashboard.module.css";
import Image from "next/image";
import Router from "next/router";
import { useRouter } from "next/navigation";
import {
  styled,
  useTheme,
  Theme,
  CSSObject,
  alpha,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SCSVG from "/assets/logo/ScLogoSvg.svg";
import SocialCrowdIcon from "/assets/logo/SocialCrowdIcon_new.png";
import ProfileDashboardNav from "components/profile/profileNav/ProfileDashboardNav";
import Stack from "@mui/material/Stack";
import { useAppStateContext } from "./state/state";
import Background from "/assets/images/scback.png";
import NewOrgDialog from "components/dialog/NewOrgDialog";
import NotificationFeed from "../knock";

import {
  Button,
  Grid,
  Link,
  Paper,
  Popover,
  Switch,
  Typography,
} from "@mui/material";
import { SearchParticipantTemp } from "components/profile/profileSearch/ProfileSearch";
import DashboardMobile from "./DashboardMobile";
import MainDialog from "components/dialog/MainDialog";
import { useEffect, useState } from "react";
import OrgSelector from "components/tailwind_mui/OrgSelector";

import lightThemeOptions from "styles/theme/lightThemeOptions";
import darkThemeOptions from "styles/theme/darkThemeOptions";
import { ThemeProvider } from "@mui/system";

import YellowIcon from "assets/images/yellowExclaimation.svg";
import {
  abbreviateNumber,
  BlackTooltip,
  FontAwesomeIcon,
} from "helpers/helpers";
import useApi from "lib/useApi";
import postAPI from "lib/postApi";

import { mutate } from "swr";
import AddCampaignDialog from "components/dialog/AddCampaignDialog/AddCampaignDialog";
import PropertyDrawer from "components/CustomUI/EditPropertiesDrawer/PropertyDefinitionsPanel";
import MaintenanceImage from "assets/images/maintenance.png";
import { useLocalStorage } from "contexts/LocalStorageContext";
import { datadogRum } from "@datadog/browser-rum";
import AnimatedIconButton from "components/common/AnimatedIconButton";
import AnimatedGradientButton from "components/common/AnimatedGradientButton";

const lightTheme = createTheme(lightThemeOptions);
const darkTheme = createTheme(darkThemeOptions);

enum SearchTempType {
  socialcrowd,
  campaign,
  participant,
}

interface SearchTemp {
  id: string;
  type: SearchTempType;
  participant?: SearchParticipantTemp;
  title: string;
  description: string;
  location?: string;
  participants?: SearchParticipantTemp[];
  moreCount?: number;
}

const drawerWidth = 230;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderRight: "0px",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
  borderRight: "0px",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 50,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const BodyHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  backgroundColor: "inherit",
  background: "linear-gradient(0deg, inherit 1%, inherit 80%)",
  backdropFilter: "blur(4px)",
  height: 50,
  // borderBottom: "1px solid #E5E5E5",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "inherit",
  boxShadow: "none",
  // boxShadow: "0px 20px 20px rgba(0, 0, 0, 0.10)",
  height: 50,

  // minHeight: "92px",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: "inherit",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
interface DashboardLayoutProps {
  children?: any;
  pageIndex: number;
}

export default function DashboardLayout({
  children,
  pageIndex,
}: DashboardLayoutProps) {
  const { windowSize } = useLocalStorage();
  const router = useRouter();
  const [openOrgDialog, setOpenOrgDialog] = useState(false);
  const [openAddCampaignDialog, setOpenAddCampaignDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isInMaintenanceMode, setIsInMaintenanceMode] = useState(false);

  const [openPropertyDrawer, setOpenPropertyDrawer] = useState(false);

  // const [audio] = useState(new Audio(chaChingSound));

  // const playSound = () => {
  //   audio.play();
  // };

  const localStorageGlobal = () => {
    if (typeof window !== "undefined") {
      return window.localStorage;
    }
  };
  const {
    data: usersDataRoot,
    error: errorUsers,
    mutate: mutateUsersData,
    isLoading: isLoadingUsers,
  } = useApi(`/api/users/my_user`, true);

  const usersData = usersDataRoot;
  const isMember = usersData ? usersData?.is_member : undefined;
  const isPending = usersData ? usersData?.is_pending : undefined;
  const fullName = usersData
    ? usersData?.first_name + " " + usersData?.last_name
    : undefined;

  const orgId = localStorageGlobal()?.orgSelected;
  const {
    data: orgs,
    error: orgsError,
    mutate: mutateOrgs,
  } = useApi(`/api/orgs/my_orgs`, true);

  // Set user attributes
  datadogRum.setUser({
    id: usersData?.id,
    name: fullName,
    email: usersData?.email,
    first_name: usersData?.first_name,
    last_name: usersData?.last_name,
    role: usersData?.role,
    last_login: new Date().toISOString(),
    current_org: orgId,
    orgs: orgs?.map((org) => {
      return {
        id: org.id,
        name: org.name,
      };
    }),
  });

  const { data: memberData, isLoading: memberLoading } = useApi(
    `/api/orgs/${orgId}/members/my_member`,
    isMember
  );

  const { data: org } = useApi(`/api/orgs/${orgId}`, isMember);

  const allData = memberData;
  const userData = allData?.user || {};

  const useTabVisibility = (onTabVisible) => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        onTabVisible();
      }
    };

    const handleWindowFocus = () => {
      onTabVisible();
    };

    useEffect(() => {
      document.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("focus", handleWindowFocus);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
        window.removeEventListener("focus", handleWindowFocus);
      };
    }, [onTabVisible]);
  };

  useTabVisibility(() => {
    mutateUsersData();
  });

  const createBirthday = () => {
    // {{API_HOST}}/api/orgs/{{organization_id}}/campaigns/add_birthday_campaign
    try {
      postAPI(`/api/orgs/${orgId}/campaigns/add_birthday_campaign`, {}).then(
        (res) => {
          handleCloseAdd();
          router.push("/campaigns");
          mutate(`/api/orgs/${orgId}/campaigns/my_campaigns`);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const createAnniversary = () => {
    // {{API_HOST}}/api/orgs/{{organization_id}}/campaigns/add_anniversary_campaign
    try {
      postAPI(`/api/orgs/${orgId}/campaigns/add_anniversary_campaign`, {}).then(
        (res) => {
          handleCloseAdd();
          router.push("/campaigns");
          mutate(`/api/orgs/${orgId}/campaigns/my_campaigns`);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  //sign user out if page is inactive for 30 minutes
  // const [lastActive, setLastActive] = useState(Date.now());
  // const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  // const debounce = (func, delay) => {
  //   let debounceTimer;
  //   return (...args) => {
  //     clearTimeout(debounceTimer);
  //     debounceTimer = setTimeout(() => func(...args), delay);
  //   };
  // };

  // const handleActivity = debounce(() => {
  //   setLastActive(Date.now());
  // }, 1000);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (Date.now() - lastActive > 1800000) {
  //       signOut();
  //     }
  //   }, 1800000);

  //   setTimeoutId(timeout);
  //   return () => clearTimeout(timeout);
  // }, [lastActive]);

  // useEffect(() => {
  //   window.addEventListener("click", handleActivity);
  //   window.addEventListener("mousemove", handleActivity);
  //   window.addEventListener("keypress", handleActivity);
  //   window.addEventListener("scroll", handleActivity);
  //   window.addEventListener("touchstart", handleActivity);
  //   window.addEventListener("touchmove", handleActivity);
  //   window.addEventListener("touchend", handleActivity);
  //   window.addEventListener("wheel", handleActivity);
  //   return () => {
  //     window.removeEventListener("click", handleActivity);
  //     window.removeEventListener("mousemove", handleActivity);
  //     window.removeEventListener("keypress", handleActivity);
  //     window.removeEventListener("scroll", handleActivity);
  //     window.removeEventListener("touchstart", handleActivity);
  //     window.removeEventListener("touchmove", handleActivity);
  //     window.removeEventListener("touchend", handleActivity);
  //     window.removeEventListener("wheel", handleActivity);
  //   };
  // }, []);

  const orgsExist = () => {
    let alreadyId = localStorageGlobal()?.orgSelected;
    return orgs
      ?.map((item) => {
        return item?.id;
      })
      .includes(alreadyId);
  };

  useEffect(() => {
    if (localStorageGlobal()?.orgSelected !== "undefined" || orgsExist()) {
      null;
    } else {
      window.localStorage.setItem(
        "orgSelected",
        orgs?.map((item) => item?.id)[0]
      );
      Router.push("/dashboard");
    }
  }, [orgs?.map((item) => item?.id)[0], memberData]);

  const Loading = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <div className="loader" style={{ position: "absolute" }}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Image
          src={SocialCrowdIcon}
          priority={true}
          alt="Icon"
          width={40}
          height={40}
          style={{ position: "absolute" }}
        />
      </div>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [usersData]);

  const state = useAppStateContext();
  const theme = useTheme();

  const [cashOrPoints, setCashOrPoints] = useState(
    localStorageGlobal()?.valueType || "cash"
  );
  const [points, setPoints] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedValueType = localStorageGlobal()?.valueType || "cash";
      setPoints(storedValueType === "cash");
    }
  }, [localStorageGlobal()?.valueType]);

  const setLocalStorages = () => {
    if (typeof window !== "undefined" && allData && userData && org) {
      // Check if valueType exists in localStorage, if not set it to "points"
      if (!localStorage.getItem("valueType")) {
        localStorage.setItem("valueType", "points");
      }

      // Define all required keys and their values
      const storageItems = {
        admin: allData?.role === "admin" ? "true" : "false",
        staff: userData?.role === "staff" ? "true" : "false",
        allMembersId: org?.all_members_crowd_id,
        userId: userData?.id,
        memberId: allData?.id,
        memberRole: allData?.role,
        manager: memberData?.is_manager,
        firstName: userData?.first_name,
        lastName: userData?.last_name,
        fullName: `${userData?.first_name} ${userData?.last_name}`,
        email: userData?.email,
      };

      // Set all items
      Object.entries(storageItems).forEach(([key, value]) => {
        localStorage.setItem(key, value?.toString() || "");
      });

      // Return true only if all values are set
      return Object.values(storageItems).every(
        (value) => value !== undefined && value !== null
      );
    }
    return false;
  };

  // Add new state to track localStorage initialization
  const [localStorageInitialized, setLocalStorageInitialized] = useState(false);

  useEffect(() => {
    if (org && allData && userData) {
      const isInitialized = setLocalStorages();
      setLocalStorageInitialized(isInitialized);
    }
  }, [allData, userData, org]);

  const { drawerOpen, setDrawerOpenState, logOut } = state;
  const firstName = userData?.first_name || "";
  const lastName = userData?.last_name || "";

  const [open, setOpen] = useState<boolean>(false);
  const [openMobileSettings, setOpenMobileSettings] = useState(false);

  const [selectedIndex, setSelectedIndex] = React.useState(pageIndex || 0);

  const [anchorElementAccount, setAnchorElementAccount] =
    React.useState<null | HTMLElement>(null);
  const openAccount = Boolean(anchorElementAccount);
  const handleClickAccount = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementAccount(event.currentTarget);
  };
  const handleCloseAccount = () => {
    setAnchorElementAccount(null);
  };

  const signOut = () => {
    window.localStorage.removeItem("admin");
    window.localStorage.removeItem("staff");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("memberId");
    router.push("/api/auth/logout");
  };

  const [anchorElementHelp, setAnchorElementHelp] =
    React.useState<null | HTMLElement>(null);
  const openHelp = Boolean(anchorElementHelp);
  const handleClickHelp = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementHelp(event.currentTarget);
  };
  const handleCloseHelp = () => {
    setAnchorElementHelp(null);
  };

  const [anchorElementNotification, setAnchorElementNotification] =
    React.useState<null | HTMLElement>(null);
  const openNotification = Boolean(anchorElementNotification);
  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementNotification(event.currentTarget);
  };
  const handleCloseNotification = () => {
    setAnchorElementNotification(null);
  };

  const [anchorElementAdd, setAnchorElementAdd] =
    React.useState<null | HTMLElement>(null);
  const openAdd = Boolean(anchorElementAdd);
  const handleClickAdd = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementAdd(event.currentTarget);
  };
  const handleCloseAdd = () => {
    setAnchorElementAdd(null);
  };

  const searchDivRef = React.useRef<HTMLDivElement>(null);
  const [anchorElementSearch, setAnchorElementSearch] =
    React.useState<null | HTMLDivElement>(null);
  const openSearch = Boolean(anchorElementSearch);
  const handleChangeSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setAnchorElementSearch(searchDivRef.current);
  };

  const handleCloseSearch = () => {
    setAnchorElementSearch(null);
  };

  // const SearchResultIcon = (props: any) => {
  //   switch (props.type) {
  //     case SearchTempType.campaign:
  //       return <MenuCampaignsIconSelected />;
  //     case SearchTempType.socialcrowd:
  //       return <MenuSocialCrowdsIconSelected />;
  //     default:
  //       return <ProfileSearch participant={props.participant} />;
  //   }
  // };

  const [themeR, setThemeR] = useState(lightTheme);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.localStorage.getItem("theme") === "dark") {
        setThemeR(darkTheme);
      } else {
        setThemeR(lightTheme);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", themeR.palette.mode);
  }, [themeR]);

  const changeTheme = () => {
    if (themeR.palette.mode === "light") {
      setThemeR(darkTheme);
    } else {
      setThemeR(lightTheme);
    }
  };

  // this fixes hydration issues
  const [admin, setAdmin] = useState(false);
  const [staff, setStaff] = useState(false);
  const [manager, setManager] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const admin = localStorageGlobal()?.admin === "true" ? true : false;
      const staff = localStorageGlobal()?.staff === "true" ? true : false;
      const manager = localStorageGlobal()?.manager === "true" ? true : false;
      setAdmin(admin);
      setStaff(staff);
      setManager(manager);
      // setLocalStorages();
    }
  }, [userData]);
  // end

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    route: string
  ) => {
    setSelectedIndex(index);
    router.push(route);
  };

  const participant = !admin && !staff && !manager;

  const drawerItems = [
    {
      title: "Dashboard",
      route: "/dashboard",
      icon: <FontAwesomeIcon icon="gauge-high" size="2" containerSize="30px" />,
      hidden: false,
      iconSelected: (
        <FontAwesomeIcon
          icon="gauge-high"
          size="2"
          color="#6F23FF"
          containerSize="30px"
        />
      ),
    },
    {
      title: "Data",
      route: "/metrics",
      icon: (
        <FontAwesomeIcon icon="chart-line-up" size="2" containerSize="30px" />
      ),
      hidden: participant,
      iconSelected: (
        <FontAwesomeIcon
          icon="chart-line-up"
          size="2"
          color="#6F23FF"
          containerSize="30px"
        />
      ),
    },
    {
      title: "Crowds",
      route: "/socialcrowds",
      icon: (
        <FontAwesomeIcon
          icon="user-group-simple"
          size="2"
          containerSize="30px"
        />
      ),
      hidden: false,
      iconSelected: (
        <FontAwesomeIcon
          icon="user-group-simple"
          size="2"
          color="#6F23FF"
          containerSize="30px"
        />
      ),
    },
    {
      title: "Campaigns",
      route: "/campaigns",
      icon: (
        <FontAwesomeIcon icon="poll-people" size="2" containerSize="30px" />
      ),
      hidden: false,
      iconSelected: (
        <FontAwesomeIcon
          icon="poll-people"
          size="2"
          color="#6F23FF"
          containerSize="30px"
        />
      ),
    },
    {
      title: "Rewards",
      route: "/pointsandrewards",
      icon: (
        <FontAwesomeIcon icon="trophy-star" size="2" containerSize="30px" />
      ),
      hidden: false,
      iconSelected: (
        <FontAwesomeIcon
          icon="trophy-star"
          size="2"
          color="#6F23FF"
          containerSize="30px"
        />
      ),
    },
  ];

  const handleDrawerOpen = () => {
    setDrawerOpenState(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpenState(false);
  };

  function AddMenuView() {
    return (
      <AddMenu
        anchorEl={anchorElementAdd}
        id="account-menu"
        open={openAdd}
        onClose={handleCloseAdd}
        onClick={handleCloseAdd}
      >
        <Stack direction="column" spacing={1} p={0}>
          {admin ? (
            <NewButtons href="/newsocialcrowd">
              {/* <FontAwesomeIcon icon="add" style="s" /> */}
              <Box style={{ paddingLeft: 0 }}>New Crowd</Box>
            </NewButtons>
          ) : null}
          <NewButtons
            component="a"
            href="https://www.cognitoforms.com/socialcrowd/newcampaignbuildrequestform"
            target="_blank"
          >
            <Box style={{ paddingLeft: 0 }}>Request Campaign</Box>
          </NewButtons>
          {staff ? (
            <NewButtons onClick={() => setOpenOrgDialog(true)}>
              {/* <FontAwesomeIcon icon="add" style="s" /> */}
              <Box style={{ paddingLeft: 0 }}>New Org</Box>
            </NewButtons>
          ) : null}
          {staff ? (
            <NewButtons onClick={createBirthday}>
              {/* <FontAwesomeIcon icon="add" style="s" /> */}
              <Box style={{ paddingLeft: 0 }}>Add Birthday Campaign</Box>
            </NewButtons>
          ) : null}
          {staff ? (
            <NewButtons onClick={createAnniversary}>
              {/* <FontAwesomeIcon icon="add" style="s" /> */}
              <Box style={{ paddingLeft: 0 }}>Add Anniversary Campaign</Box>
            </NewButtons>
          ) : null}
          {staff ? (
            <NewButtons onClick={() => setOpenAddCampaignDialog(true)}>
              {/* <FontAwesomeIcon icon="add" style="s" /> */}
              <Box style={{ paddingLeft: 0 }}>Add Campaign</Box>
            </NewButtons>
          ) : null}

          {/* <NewButtons href="/newcampaign">
            <MenuCampaigns />
            <Box style={{ paddingLeft: 5 }}>New Campaign</Box>
          </NewButtons> */}
        </Stack>
      </AddMenu>
    );
  }

  function AccountMenuView() {
    return (
      <AccountMenu
        anchorEl={anchorElementAccount}
        id="account-menu"
        open={openAccount}
        onClose={handleCloseAccount}
        onClick={handleCloseAccount}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <span className={styles.menuName}>{`${firstName} ${lastName}`}</span>
        {/* <Link href="/profilesetting" underline="none" color="inherit">
          <MenuItem>
            <ListItemIcon>
              <AccountCircleOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
        </Link> */}
        {/* <Box className={styles.hidden}> */}
        <Link href="/settings" underline="none" color="inherit">
          <MenuItem>
            <ListItemIcon>
              <FontAwesomeIcon icon="gear" size="1" color="black" />
            </ListItemIcon>
            Settings
          </MenuItem>
        </Link>
        {/* </Box> */}

        {/* <Stack p={2} alignItems="center" onClick={(e) => e.stopPropagation()}>
          <MaterialUISwitch
            id="theme-switch"
            checked={themeR.palette.mode === "dark"}
            onChange={changeTheme}
          />
        </Stack> */}

        {/* <MenuItem
          onClick={() => setOpenMobileSettings(true)}
          className={styles.mobileShow}
        >
          <ListItemIcon>
            <SettingsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}

        <MenuItem onClick={() => setOpen(true)}>
          <ListItemIcon>
            <FontAwesomeIcon icon="person-to-door" size="1" color="black" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </AccountMenu>
    );
  }

  function NeedHelp() {
    return (
      <Popover
        sx={{ padding: "20px" }}
        id={"help"}
        open={openHelp}
        anchorEl={anchorElementHelp}
        onClose={handleCloseHelp}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ padding: "20px" }}>
          <Typography variant="h6" sx={{ marginBottom: 1.0 }}>
            Need Help?
          </Typography>
          <Typography variant="subtitle2">
            Contact the SocialCrowd team at
          </Typography>
          <Link
            sx={{ fontWeight: 800 }}
            href="https://getsocialcrowd.com/support"
            target="_blank"
            underline="none"
          >
            SocialCrowd Help
          </Link>
        </div>
      </Popover>
    );
  }

  function NotificationPopover() {
    return (
      <Popover
        id={"help"}
        open={openNotification}
        anchorEl={anchorElementNotification}
        onClose={handleCloseNotification}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ width: "380px" }}>
          <Typography
            variant="h6"
            sx={{ margin: 1.0, marginLeft: 5.0, marginTop: 3.0 }}
          >
            Notification
          </Typography>
          <TempNotification />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "16px 0px",
          }}
        >
          <Button href="/notifications">Show All Notifications</Button>
        </div>
      </Popover>
    );
  }

  const AccountMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 200,
      color:
        theme.palette.mode === "light"
          ? "rgba(65, 13, 103)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const AddMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 200,
      transform: "translate(0, -90px) !important",
      // transform: "translate(0, -130px) !important", // todo when two items - for create campaign
      background: "transparent",
      "@media (max-width: 900px)": {
        transform: "translate(0, 50px) !important",
      },
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const [searchTerm, setSearchTerm] = useState<string>("");

  const goToPoints = () => {
    router.push("/pointsandrewards");
  };

  const stylesBackground = {
    paperContainer: {
      height: "100%",
      backgroundColor: "inherit",
      backgroundImage: `url(${Background.src})`,
      width: "100%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center top",
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeR}>
        <AddCampaignDialog
          open={openAddCampaignDialog}
          onClose={() => setOpenAddCampaignDialog(false)}
        />
        {isInMaintenanceMode ? (
          <Paper
            sx={stylesBackground.paperContainer}
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <img
              height="300px"
              alt="maintenance"
              src={MaintenanceImage.src.toString()}
            />
            <Typography variant="h3" sx={{ fontWeight: 800, mt: 5 }}>
              We will be back soon!
            </Typography>
            <Typography sx={{ fontWeight: 400, mt: 2 }}>
              SocialCrowd is currently undergoing scheduled maintenance to
              improve your experience.
            </Typography>
            <Typography sx={{ fontWeight: 400 }}>
              We apologize for this temporary inconvenience. Please check back
              soon!
            </Typography>
            <Button
              sx={{ mt: 3 }}
              className="tailwind-button"
              onClick={() => window.open("https://getsocialcrowd.com/")}
            >
              Visit Homepage
            </Button>
            <Stack sx={{ position: "absolute", bottom: 20, right: 100 }}>
              <SCSVG />
            </Stack>
          </Paper>
        ) : loading || memberLoading ? (
          <Paper
            sx={stylesBackground.paperContainer}
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Loading />
          </Paper>
        ) : isMember && userData?.first_name !== null ? (
          <>
            {windowSize[0] <= 900 ? (
              <DashboardMobile
                handleClickAccount={handleClickAccount}
                openAccount={openAccount}
                handleClickNotification={handleClickNotification}
                handleClickAdd={handleClickAdd}
                userData={userData}
                drawerItems={drawerItems}
                handleChangeSearch={handleChangeSearch}
                searchDivRef={searchDivRef}
              >
                {children}
              </DashboardMobile>
            ) : null}

            <MainDialog
              open={open}
              handleApprove={signOut}
              icon={<YellowIcon />}
              closeText={"Cancel"}
              approveText={"Confirm"}
              message={
                <Stack fontWeight="bold" fontSize="1.2rem">
                  Are you sure you want to logout?
                </Stack>
              }
              handleClose={handleClose}
              handleRequest={false}
            />
            <Box
              className={styles.hidden}
              style={stylesBackground.paperContainer}
              sx={{
                display: "flex",
                // backgroundColor: "#F7F8FC",
                minHeight: "100%",
                position: "relative",
              }}
            >
              <AppBar
                position="fixed"
                open={drawerOpen}
                sx={{ background: "transparent" }}
              >
                <BodyHeader>
                  {drawerOpen === false ? (
                    <div style={{ marginLeft: "14px" }}>
                      <Image
                        src={SocialCrowdIcon}
                        priority={true}
                        alt="Icon"
                        width={30}
                        height={30}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <IconButton
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                      marginLeft: 4,
                      marginRight: 2.5,
                      ...(drawerOpen && { display: "none" }),
                    }}
                  >
                    <FontAwesomeIcon icon="right-to-line" size="1" />
                  </IconButton>
                  <IconButton
                    aria-label="close drawer"
                    edge="start"
                    onClick={handleDrawerClose}
                    sx={{
                      marginLeft: 1.5,
                      marginRight: 2.5,
                      ...(!drawerOpen && { display: "none" }),
                    }}
                  >
                    {theme.direction === "rtl" ? (
                      <FontAwesomeIcon icon="left-to-line" size="1" />
                    ) : (
                      <FontAwesomeIcon icon="left-to-line" size="1" />
                    )}
                  </IconButton>

                  <Box sx={{ flexGrow: 1 }} />

                  <AnimatedGradientButton
                    onClick={goToPoints}
                    title={`Redeem ${
                      points
                        ? "$" + allData?.wallet_amount / 100
                        : abbreviateNumber(allData?.wallet_amount)
                    }`}
                  />
                  {/* <Button
                    sx={{ p: "5px 30px", color: " white" }}
                    onClick={goToPoints}
                    className="button-85"
                    // color="primary"
                  >
                    {points ? "$" : ""}
                    {points
                      ? allData?.wallet_amount / 100
                      : abbreviateNumber(allData?.wallet_amount)}{" "}
                    {points ? "Cash" : "Points"}
                  </Button> */}
                  {/* <IconButton
              onClick={handleClickNotification}
              aria-label="open notifications"
              edge="start"
              sx={{
                marginLeft: 1.5,
                marginRight: 1.0,
              }}
            >
              <NavNotificationIcon
                sx={{
                  fontSize: 24,
                  fill: openNotification ? "#6F23FF" : "black",
                }}
              />
            </IconButton> */}
                  <Stack
                    ml={3}
                    direction="row"
                    spacing={0.5}
                    alignItems="center"
                  >
                    <Stack>Points</Stack>
                    <SwitcherPoints
                      checked={points}
                      onChange={(e) => {
                        let string = e.target.checked.toString();
                        window.localStorage.setItem(
                          "valueType",
                          string === "true" ? "cash" : "points"
                        );
                        setPoints(e.target.checked);
                        router.refresh();
                      }}
                    />
                    <Stack>Cash</Stack>
                  </Stack>

                  {/* <Button
                    onClick={playSound}
                    variant="contained"
                    color="primary"
                  >
                    Play Cha-Ching Sound
                  </Button> */}

                  <OrgSelector />
                  {/* <IconButton sx={{ mr: 2 }}>
                  <NavNotificationIcon sx={{ fontSize: 24 }} />
                </IconButton>  */}

                  {staff ? (
                    <div style={{ marginRight: "10px" }}>
                      <BlackTooltip title="Properties Manager" arrow>
                        <AnimatedIconButton
                          onClick={() => setOpenPropertyDrawer(true)}
                          ariaLabel="Open Property Drawer"
                          icon={
                            <FontAwesomeIcon icon="print-magnifying-glass" />
                          }
                        />
                      </BlackTooltip>
                    </div>
                  ) : null}

                  <NotificationFeed />
                  <Box sx={{ ml: 2 }} />

                  <ProfileDashboardNav
                    userData={userData}
                    onClick={handleClickAccount}
                    isOpen={openAccount}
                  />
                  <Box sx={{ mr: 2, ml: 2 }} />
                </BodyHeader>
              </AppBar>
              <PropertyDrawer
                openPropertyDrawer={openPropertyDrawer}
                setOpenPropertyDrawer={setOpenPropertyDrawer}
              />
              <Drawer
                variant="permanent"
                open={drawerOpen}
                sx={{ height: "100vh" }}
              >
                <DrawerHeader>
                  {drawerOpen === true ? (
                    <Box width="190px">
                      <SCSVG />
                    </Box>
                  ) : null}
                  {/* <Image src={SocialCrowdLogo} alt="Logo" width={200} height={28} /> */}
                </DrawerHeader>

                <List
                  sx={{
                    padding: 0,
                    // selected and (selected + hover) states
                    "&& .Mui-selected, && .Mui-selected:hover": {
                      bgcolor: "rgba(111,35,255,.1)",
                      "&, & .MuiListItemIcon-root": {
                        color: "inherit",
                      },
                    },
                    // hover states
                    "& .MuiListItemButton-root:hover": {
                      bgcolor: "rgba(111,35,255,.2)",
                      "&, & .MuiListItemIcon-root": {
                        color: "inherit",
                      },
                    },
                  }}
                >
                  {drawerItems.map((item: any, index: number) => (
                    <ListItemButton
                      selected={selectedIndex === index}
                      onClick={(event) => {
                        window.localStorage.removeItem("crowdSelected");
                        handleListItemClick(event, index, item.route);
                      }}
                      key={item.title}
                      sx={{
                        display: item?.hidden ? "none" : "flex",
                        minHeight: 50,
                        margin: 1,
                        borderRadius: 2,
                        justifyContent: drawerOpen ? "initial" : "center",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 25,
                          mr: drawerOpen ? 2 : "auto",
                          justifyContent: "center",
                          alignItems: "center",
                          color:
                            selectedIndex === index ? "#6F23FF" : "inherit",
                          fill:
                            selectedIndex === index
                              ? "#6F23FF"
                              : "currentColor",
                          marginLeft: "2px",
                          fontSize: 10,
                        }}
                      >
                        {selectedIndex === index
                          ? item?.iconSelected
                          : item?.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item?.title}
                        primaryTypographyProps={{
                          fontSize: "16px",
                          fontWeight: selectedIndex === index ? "500" : "300",
                        }}
                        sx={{
                          opacity: drawerOpen ? 1 : 0,
                          fontSize: 16,
                          // color: selectedIndex === index ? "#000000" : "black",
                        }}
                      />
                      {/* {drawerOpen && (index === 1 || index === 2) ? (
                  <Box
                    sx={{
                      background: "rgba(0,0,0,.05)",
                      color: "black",
                      borderRadius: 2,
                      width: "35px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    2
                  </Box>
                ) : null} */}
                    </ListItemButton>
                  ))}
                </List>
                <Box sx={{ flexGrow: 1 }} />

                <Stack spacing={1} pb={2} alignItems="center">
                  {staff || admin || manager ? (
                    <ViewButton
                      onClick={handleClickAdd}
                      style={{
                        ...(!drawerOpen && {
                          display: "none",
                        }),
                        // marginBottom: "80px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon="add"
                        style="s"
                        sx={{ mr: 1, fontSize: "0.9rem" }}
                      />{" "}
                      New
                    </ViewButton>
                  ) : null}
                  <Box>
                    <Link
                      sx={{
                        fontWeight: 800,
                        color: "#6F23FF",
                      }}
                      href="https://getsocialcrowd.com/support"
                      target="_blank"
                    >
                      Support
                    </Link>
                  </Box>
                </Stack>

                {staff || admin || manager ? (
                  <IconButton
                    onClick={handleClickAdd}
                    style={{
                      ...(drawerOpen && { display: "none" }),
                      backgroundColor: "#6F23FF",
                      width: 40,
                      height: 40,
                      borderRadius: 30,
                      margin: 1.5,
                      marginBottom: "80px",
                      alignSelf: "center",
                    }}
                  >
                    <FontAwesomeIcon icon="plus" size="1" color="white" />
                  </IconButton>
                ) : null}
              </Drawer>
              <Box
                m={1}
                mt={"65px"}
                justifyContent="center"
                display="flex"
                component="main"
                sx={{
                  flexGrow: 1,
                  p: 3,
                  borderRadius: 2,
                  minHeight: "92vh",
                  background: "rgba(170,174,199,.1)",
                }}
              >
                <Box width={"100%"} maxWidth={1500}>
                  {children}
                </Box>
              </Box>
              <AccountMenuView />
              <NeedHelp />
              <NotificationPopover />
              <AddMenuView />
              {/* <SearchResultView /> */}
              <NewOrgDialog
                open={openOrgDialog}
                handleClose={() => setOpenOrgDialog(false)}
              />
            </Box>
          </>
        ) : !memberLoading && userData === undefined ? (
          <Paper
            sx={stylesBackground.paperContainer}
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Paper
              elevation={0}
              sx={{
                pt: 3,
                pb: 3,
                pr: 10,
                pl: 10,
                marginBottom: "200px",
                width: "100vw",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box width="190px">
                <SCSVG />
              </Box>
              <Box pb={7}>
                Your session has expired. Please sign out and sign in again.
              </Box>

              <Button className="tailwind-button" onClick={signOut}>
                Sign Out
              </Button>
            </Paper>
          </Paper>
        ) : !isMember && isPending ? (
          <Paper
            sx={stylesBackground.paperContainer}
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Paper
              elevation={0}
              sx={{
                pt: 3,
                pb: 3,
                pr: 10,
                pl: 10,
                marginBottom: "200px",
                width: "100vw",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box width="190px" mb={2}>
                <SCSVG />
              </Box>

              <h4>
                Welcome to SocialCrowd. You are currently pending, once
                approved, you will be notified.
              </h4>
              <Box sx={{ mb: 1, mt: 1 }} />
              <Button className="tailwind-button" onClick={signOut}>
                Sign Out
              </Button>
            </Paper>
          </Paper>
        ) : isMember !== undefined && !isMember && !isPending ? (
          <Paper
            sx={stylesBackground.paperContainer}
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Paper
              elevation={0}
              sx={{
                pt: 3,
                pb: 3,
                pr: 10,
                pl: 10,
                marginBottom: "200px",
                // width: "100vw",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                // boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.2)",
              }}
            >
              <Box width="190px">
                <SCSVG />
              </Box>
              <Box width="400px" mb={3} mt={3}>
                You are not currently a member of an active org. Please reach
                out to your org admin for an invite or crowd link.
              </Box>

              <Button className="btn btn-primary" onClick={signOut}>
                Sign Out
              </Button>
            </Paper>
          </Paper>
        ) : null}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const TempNotification = () => {
  return (
    <List dense={true}>
      <ListItem disablePadding>
        <ListItemButton>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <div
                style={{
                  width: "6px",
                  height: "6px",
                  backgroundColor: "#6F23FF",
                  margin: "8px 0px 0px 10px",
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <span style={{ fontWeight: 800, color: "#6F23FF" }}>50m</span>
            </Grid>
            <Grid item xs={9}>
              <span style={{ fontWeight: 800 }}>
                You were gift{" "}
                <span style={{ color: "#6F23FF" }}>500 Points!</span>
              </span>
            </Grid>
          </Grid>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <div
                style={{
                  width: "6px",
                  height: "6px",
                  backgroundColor: "#6F23FF",
                  margin: "8px 0px 0px 10px",
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <span style={{ fontWeight: 800, color: "#6F23FF" }}>2hr</span>
            </Grid>
            <Grid item xs={9}>
              <span style={{ fontWeight: 800 }}>
                Added to the <span style={{ color: "#6F23FF" }}>Marketing</span>{" "}
                SocialCrowd.
              </span>
            </Grid>
          </Grid>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <span>1d</span>
            </Grid>
            <Grid item xs={9}>
              <span>
                Your pending{" "}
                <span style={{ color: "#6F23FF" }}>150 Points</span> are now
                available!
              </span>
            </Grid>
          </Grid>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <span>2d</span>
            </Grid>
            <Grid item xs={9}>
              <span>
                You left the <span style={{ color: "#6F23FF" }}>Sales</span>{" "}
                SocialCrowd!
              </span>
            </Grid>
          </Grid>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <span>2d</span>
            </Grid>
            <Grid item xs={9}>
              <span>
                You earned{" "}
                <span style={{ color: "#6F23FF" }}>1,250 Points!</span>
              </span>
            </Grid>
          </Grid>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <span>4d</span>
            </Grid>
            <Grid item xs={9}>
              <span>
                You joined the{" "}
                <span style={{ color: "#6F23FF" }}>Social Media</span>{" "}
                SocialCrowd.
              </span>
            </Grid>
          </Grid>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <span>1w</span>
            </Grid>
            <Grid item xs={9}>
              <span>
                You earned <span style={{ color: "#6F23FF" }}>860 Points!</span>
              </span>
            </Grid>
          </Grid>
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <span>2w</span>
            </Grid>
            <Grid item xs={9}>
              <span>
                You earned <span style={{ color: "#6F23FF" }}>350 Points!</span>
              </span>
            </Grid>
          </Grid>
        </ListItemButton>
      </ListItem>
    </List>
  );
};

const NewButtons = styled(Button)<{
  component?: React.ElementType;
  target?: string;
  rel?: string;
}>({
  height: "40px",
  width: 200,
  borderRadius: "28px",
  boxShadow: "none",
  textTransform: "none",
  fontSize: "0.8rem",
  padding: "10px",
  // border: '2px solid',
  backgroundColor: "white",
  border: "2px solid #6F23FF",
  color: "#000",
  // fontFamily: [
  //   "-apple-system",
  //   "BlinkMacSystemFont",
  //   '"Segoe UI"',
  //   "Roboto",
  //   '"Helvetica Neue"',
  //   "Arial",
  //   "sans-serif",
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(","),
  fontWeight: "800",
  "&:hover": {
    backgroundColor: "#e8e4fd",
    borderColor: "#6F23FF",
    boxShadow: "none",
    color: "#6F23FF",
  },
  "&:active": {
    backgroundColor: "#6F23FF11",
    borderColor: "#6F23FF",
    boxShadow: "none",
    color: "#6F23FF",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const ViewButton = styled(Button)({
  height: "45px",
  borderRadius: "50px",
  boxShadow: "none",
  margin: 20,
  textTransform: "none",
  fontSize: 18,
  maxWidth: 100,
  alignSelf: "center",
  padding: "12px 26px",
  border: "2px solid",
  backgroundColor: "#6F23FF",
  borderColor: "#6F23FF",
  color: "#fff",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontWeight: "600",
  "&:hover": {
    // backgroundColor: "rgba(98,85,164,0.1)",
    borderColor: "#6F23FF",
    color: "#6F23FF",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#6F23FF22",
    borderColor: "#6F23FF",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const SwitcherPoints = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",

      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundColor: "#46B358",
        borderRadius: "50%",
        backgroundPositionX: "right",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 18 18"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M3.924 15.98V14.18C3.372 14.12 2.814 13.97 2.25 13.73C1.698 13.49 1.218 13.184 0.81 12.812L1.746 11.408C2.166 11.744 2.592 12.014 3.024 12.218C3.468 12.422 3.93 12.524 4.41 12.524C4.998 12.524 5.436 12.386 5.724 12.11C6.012 11.834 6.156 11.456 6.156 10.976C6.156 10.58 6.036 10.25 5.796 9.986C5.568 9.71 5.268 9.47 4.896 9.266C4.524 9.062 4.128 8.858 3.708 8.654C3.288 8.438 2.892 8.198 2.52 7.934C2.148 7.67 1.842 7.352 1.602 6.98C1.374 6.596 1.26 6.122 1.26 5.558C1.26 4.718 1.5 4.022 1.98 3.47C2.472 2.906 3.12 2.552 3.924 2.408V0.571999H5.364V2.372C5.94 2.444 6.432 2.612 6.84 2.876C7.26 3.128 7.638 3.428 7.974 3.776L6.912 4.982C6.576 4.67 6.252 4.436 5.94 4.28C5.628 4.112 5.256 4.028 4.824 4.028C4.344 4.028 3.96 4.154 3.672 4.406C3.396 4.646 3.258 5 3.258 5.468C3.258 5.828 3.372 6.128 3.6 6.368C3.84 6.608 4.146 6.824 4.518 7.016C4.89 7.208 5.286 7.406 5.706 7.61C6.126 7.802 6.522 8.036 6.894 8.312C7.266 8.576 7.566 8.912 7.794 9.32C8.034 9.716 8.154 10.22 8.154 10.832C8.154 11.684 7.908 12.404 7.416 12.992C6.936 13.58 6.252 13.958 5.364 14.126V15.98H3.924Z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#6F23FF",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 25 25"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));
