import { useLocalStorage } from "@/contexts/LocalStorageContext";
import useApi from "@/lib/useApi";
import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import TextField from "@/components/common/Textfield";
import Dropdown from "@/components/common/Dropdown";
import SocialCrowdIcon from "/assets/logo/SocialCrowdIcon_new.png";
import Image from "next/image";
import putAPI from "@/lib/putApi";
import { mutate } from "swr";
import { useRouter } from "next/router";
interface UserDataDialogProps {}

export const UserDataDialog: React.FC<UserDataDialogProps> = ({}) => {
  const [formData, setFormData] = useState({
    birthday_day: "",
    birthday_month: "",
    cell_number: "",
    first_name: "",
    last_name: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const router = useRouter();
  const { orgId } = useLocalStorage();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);

  const { data: memberData, isLoading: memberLoading } = useApi(
    `/api/orgs/${orgId}/members/my_member`,
    true
  );

  const formatPhoneNumber = (value: string) => {
    // Remove all non-digit characters
    const digits = value.replace(/\D/g, "");

    // Apply mask: (XXX) XXX-XXXX
    if (digits.length <= 3) return digits;
    if (digits.length <= 6) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(
      6,
      10
    )}`;
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Convert month name to number if birthday_month is present
    const monthNameToNumber: { [key: string]: string } = {
      January: "1",
      February: "2",
      March: "3",
      April: "4",
      May: "5",
      June: "6",
      July: "7",
      August: "8",
      September: "9",
      October: "10",
      November: "11",
      December: "12",
    };

    const updateData = missingFields.reduce(
      (acc, field) => ({
        ...acc,
        [field]:
          field === "birthday_month"
            ? monthNameToNumber[formData[field]]
            : field === "cell_number"
            ? formData[field].replace(/[()-\s]/g, "") // Remove parentheses, spaces, and dashes
            : formData[field as keyof typeof formData],
      }),
      {}
    );

    const response = await putAPI(
      `/api/users/${memberData?.user?.id}`,
      updateData
    ).then((res) => {
      mutate(`/api/orgs/${orgId}/members/my_member`);
      setIsDialogOpen(false);
    });

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (memberData?.user) {
      const missing: string[] = [];
      if (!memberData.user.birthday_day && memberData.user.birthday_day !== 0)
        missing.push("birthday_day");
      if (
        !memberData.user.birthday_month &&
        memberData.user.birthday_month !== 0
      )
        missing.push("birthday_month");
      if (
        memberData.user.cell_number === null ||
        memberData.user.cell_number === "" ||
        memberData.user.cell_number === 0
      )
        missing.push("cell_number");
      if (!memberData.user.first_name || memberData.user.first_name === "")
        missing.push("first_name");
      if (!memberData.user.last_name || memberData.user.last_name === "")
        missing.push("last_name");

      if (missing.length > 0) {
        setMissingFields(missing);
        setIsDialogOpen(true);
      }
    }
  }, [memberData]);

  if (!isDialogOpen) return null;

  const signOut = () => {
    window.localStorage.removeItem("admin");
    window.localStorage.removeItem("staff");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userId");
    window.localStorage.removeItem("memberId");
    router.push("/api/auth/logout");
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      className="relative z-[999999]"
    >
      <div
        className="fixed inset-0 bg-black/90 backdrop-blur-lg"
        aria-hidden="true"
      />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="w-full max-w-md rounded-2xl bg-white p-6 shadow-xl">
          <div className="flex justify-center mb-4">
            <Image
              src={SocialCrowdIcon}
              alt="logo"
              width={50}
              height={50}
              priority
            />
          </div>

          <div className="text-lg text-center font-medium leading-6 text-gray-900 mb-4">
            Complete Your Profile
          </div>

          <div className="text-xs text-center text-gray-500 mb-4">
            Please fill out the missing required fields below to finalize your
            profile.
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            {missingFields.includes("first_name") && (
              <TextField
                placeholder="First Name"
                value={formData.first_name}
                onChange={(e) =>
                  setFormData({ ...formData, first_name: e.target.value })
                }
              />
            )}

            {missingFields.includes("last_name") && (
              <TextField
                placeholder="Last Name"
                value={formData.last_name}
                onChange={(e) =>
                  setFormData({ ...formData, last_name: e.target.value })
                }
              />
            )}

            {missingFields.includes("cell_number") && (
              <TextField
                placeholder="Cell Number"
                value={formData.cell_number}
                onChange={(e) => {
                  const formatted = formatPhoneNumber(e.target.value);
                  setFormData({ ...formData, cell_number: formatted });
                }}
                maxLength={14} // (XXX) XXX-XXXX = 14 characters
              />
            )}

            {missingFields.includes("birthday_month") && (
              <Dropdown
                options={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ]}
                label="Birthday Month"
                value={formData.birthday_month}
                onChange={(value) =>
                  setFormData({ ...formData, birthday_month: value })
                }
              />
            )}

            {missingFields.includes("birthday_day") && (
              <Dropdown
                options={Array.from({ length: 31 }, (_, i) =>
                  (i + 1).toString()
                )}
                label="Birthday Day"
                value={formData.birthday_day}
                onChange={(value) =>
                  setFormData({ ...formData, birthday_day: value })
                }
              />
            )}

            <button
              type="submit"
              className="btn btn-success btn-sm w-full"
              disabled={
                isSubmitting ||
                !missingFields.every(
                  (field) => formData[field as keyof typeof formData]
                )
              }
            >
              {isSubmitting ? (
                <>
                  <span className="loading loading-spinner loading-xs"></span>
                  <span className="ml-2">Submitting...</span>
                </>
              ) : (
                "Submit"
              )}
            </button>
            <button
              type="button"
              className="btn btn-error btn-sm w-full"
              onClick={signOut}
            >
              Logout
            </button>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

export default UserDataDialog;
