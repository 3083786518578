const putter = async (url: string, token: string | null, data: any) => {
  if (!token) {
    throw new Error("Token is not available");
  }

  const newUrl = process.env.NEXT_PUBLIC_CUSTOMER_API_HOST + url;

  try {
    const res = await fetch(newUrl, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    // Check if the response status is 200 or 204 (common for PUT requests)
    if (res.status === 200 || res.status === 204) {
      // If the response is empty or status is 204, return a success message
      const contentLength = res.headers.get('content-length');
      if (res.status === 204 || contentLength === null || contentLength === '0') {
        return { success: true, message: "Data updated successfully" };
      }

      // If there's content and it's JSON, parse it
      const contentType = res.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const text = await res.text();
        if (text) {
          try {
            const jsonData = JSON.parse(text);
            return jsonData;
          } catch (e) {
            console.error("Error parsing JSON response:", e);
            return { success: true, message: "Data updated successfully, but response parsing failed." };
          }
        } else {
          return { success: true, message: "Data updated successfully" };
        }
      }

      // If there's content but it's not JSON, return it as text
      const message = await res.text();
      return { success: true, message };
    }

    // If the status is not 200 or 204, parse the error response
    let errorResponse;
    try {
      errorResponse = await res.json();
    } catch (e) {
      // Fallback to text if JSON parsing fails
      errorResponse = await res.text();
    }
    throw new Error(JSON.stringify({
      status: res.status,
      statusText: res.statusText,
      errorData: errorResponse
    }));
  } catch (error) {
    console.error("Error updating data", error);
    throw error;
  }
};


const fetchToken = async () => {
  try {
    const res = await fetch("/api/token", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(`Error fetching access token: ${res.statusText}`);
    }

    const data = await res.json();
    return data.accessToken;
  } catch (error) {
    console.error("Error fetching access token", error);
    throw error;
  }
};

const putAPI = async (url: string, data: any) => {
  try {
    const token = await fetchToken();
    return await putter(url, token, data);
  } catch (error) {
    if (error instanceof Error) {
      try {
        const parsedError = JSON.parse(error.message);
        console.error("API Error:", parsedError);
        throw new Error(`API Error: ${parsedError.status} ${parsedError.statusText}\n${JSON.stringify(parsedError.errorData)}`);
      } catch {
        // If parsing fails, it's not our custom error object
        console.error("Error in API call:", error.message);
        throw error;
      }
    } else {
      console.error("Unknown error in API call:", error);
      throw new Error("Unknown error in API call");
    }
  }
};

export default putAPI;